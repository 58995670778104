export enum PosthogFeatureFlags {
    DFU = 'direct-file-upload',
    OPPORTUNITIES_PAGE_ENABLED = 'opportunities-page-enabled',
    OPPORTUNITY_LANDING_PAGE = 'opportunity-landing-page',
    PARTNER_REPAYMENT_DASHBOARD_VIEW = 'partner-repayment-dashboard-view',
    SHOW_MULTIPLE_APPLICATIONS = 'show-multiple-applications',
    KNOWLEDGE_CENTER_CARD_ENABLED = 'knowledge-center-card-enabled',
    CONSOLIDATED_ELIGIBILITY_CRITERIA_ENABLED = 'consolidated-eligibility-criteria-enabled',
    TALENT_WELCOME_LOGIN_LINKS_V2 = 'talent-welcome-login-links-v2',
    FINANCING_REDIRECT_TO_ESL = 'financing-redirect-to-esl',
    MY_BENEFITS_PAGE_ENABLED = 'my-benefits-page-enabled',
    FUND_2_ENABLED = 'fund-2-enabled',
    SEPARATE_ATTRACT_UPSKILL_FLOWS_ENABLED = 'separate-attract-upskill-flows-enabled',
}
